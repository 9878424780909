:root {

  --text-color-100: #e3e3e3;
  --background-color: #cdcdcd;
  --table-bg-color: #424242;
  --chart-bg-color: #424242;
  --active-row-bg-color: #616161;
  --active-row-color: #ffffff;
  --active-cell-bg-color: #2c2c2c;
  --active-cell-color: #ffffff;
  --cell-border-color: #ffffff;
  --cell-bottom-border-color: #757575;
  --scroll-bar-bg-color: #212121;
  --scroll-bar-color: #696969;
  --active-scroll-bar-color: #bdbdbd;
  --chart-header-bg-color: #2e2e2e;
  --header-active-cell-bg-color: #353535;
  --header-active-cell-color: #f6f6f6;
  --buttons-bg-color: #2e2e2e;
  --buttons-color: #c5c5c5;
  --nav-header-bg-color: #212121;
  --nav-header-color: #ffffff;
  --facet-title-color: #ffffff;
  --reports-drop-down-bg: #f6f6f6;
  --reports-drop-down-text: #e0e0e0;
  --horizontal-facet-button: #424242;
  --disco-expansion-panel-body:#212121;
  //Variables for charts
  --chart-text-color: #e0e0e0;
  // --single-chart: #00AEEC;
  // --single-chart-border: #bdbdbd;
  --grid-lines: #a0a0a0;
  --inactive-text: #bdbdbd;
  --chart-card-bg:  #424242;
  --chart-area: #424242;
  --label-text-color: #ffffff;
}

:root .light {

  --text-color: #212121;
  --text-color-100: #424242;
  --background-color: #e8f3f8;
  --table-bg-color: #ffffff;
  --chart-bg-color: #eeeeee;
  --active-row-bg-color: #eeeeee;
  --active-row-color: #212121;
  --active-cell-bg-color: #00b1a2;
  --active-cell-color: #ffffff;
  --cell-border-color: #ffffff;
  --cell-bottom-border-color: #e0e0e0;
  --scroll-bar-bg-color: #eeeeee;
  --scroll-bar-color: #bdbdbd;
  --active-scroll-bar-color: #616161;
  --chart-header-bg-color: #eeeeee;
  --header-active-cell-bg-color: #bdbdbd;
  --header-active-cell-color: #212121;
  --buttons-bg-color: #fafafa;
  --buttons-color: #4e4e4e;
  --nav-header-bg-color: #ffffff;
  --nav-header-color: #8dcfee;
  --facet-title-color: #212121;
  --reports-drop-down-bg: #f6f6f6;
  --reports-drop-down-text: #424242;
  --horizontal-facet-button: #36a096;
  --disco-expansion-panel-body:#bdbdbd;
  --chart-card-bg: #f1f1f1;
  //Variables for charts
  --chart-text-color: #e0e0e0;
  // --single-chart: #00AEEC;
  // --single-chart-border: #bdbdbd;
  --grid-lines: #a0a0a0;
  --inactive-text: #bdbdbd;
  --chart-area: #ffffff;
  --label-text-color: #ffffff;
}


:root .calson {
  --dark-600: #274577;
  --dark-A100: #212121;
  --base-color: #902f53;
  --text-color: #212121;
  --text-color-100: #424242;
  --background-color: #e8f3f8;
  --table-bg-color: #ffffff;
  --chart-bg-color: #eeeeee;
  --active-row-bg-color: #eeeeee;
  --active-row-color: #212121;
  --active-cell-bg-color: #00b1a2;
  --active-cell-color: #ffffff;
  --cell-border-color: #ffffff;
  --cell-bottom-border-color: #e0e0e0;
  --scroll-bar-bg-color: #eeeeee;
  --scroll-bar-color: #bdbdbd;
  --active-scroll-bar-color: #616161;
  --chart-header-bg-color: #eeeeee;
  --header-active-cell-bg-color: #bdbdbd;
  --header-active-cell-color: #212121;
  --buttons-bg-color: #e1e1e1;
  --buttons-color: #4e4e4e;
  --nav-header-bg-color: #ffffff;
  --nav-header-color: #8dcfee;
  --facet-title-color: #212121;
  --reports-drop-down-bg: #f6f6f6;
  --reports-drop-down-text: #424242;
  --horizontal-facet-button:#e1e1e1;

  --disco-expansion-panel-body:#bdbdbd;
  --chart-card-bg: #f1f1f1;
  //Variables for charts
  --chart-text-color: #e0e0e0;
  // --single-chart: #00AEEC;
  // --single-chart-border: #bdbdbd;
  --grid-lines: #a0a0a0;
  --inactive-text: #bdbdbd;
  --chart-area: #ffffff;
  --label-text-color: #ffffff;
}

:root .dark{

  --text-color-100: #e3e3e3;
  --background-color: #cdcdcd;
  --table-bg-color: #424242;
  --chart-bg-color: #424242;
  --active-row-bg-color: #ee8787;
  --active-row-color: #ffffff;
  --active-cell-bg-color: #2c2c2c;
  --active-cell-color: #ffffff;
  --cell-border-color: #ffffff;
  --cell-bottom-border-color: #757575;
  --scroll-bar-bg-color: #212121;
  --scroll-bar-color: #696969;
  --active-scroll-bar-color: #bdbdbd;
  --chart-header-bg-color: #2e2e2e;
  --header-active-cell-bg-color: #353535;
  --header-active-cell-color: #f6f6f6;
  --buttons-bg-color: #2e2e2e;
  --buttons-color: #c5c5c5;
  --nav-header-bg-color: #212121;
  --nav-header-color: #ffffff;
  --facet-title-color: #ffffff;
  --reports-drop-down-bg: #424242;
  --reports-drop-down-text: #e0e0e0;
  --horizontal-facet-button: #424242;
  --disco-expansion-panel-body:#212121;
  //Variables for charts
  --chart-text-color: #e0e0e0;
  // --single-chart: #00AEEC;
  // --single-chart-border: #bdbdbd;
  --grid-lines: #a0a0a0;
  --inactive-text: #bdbdbd;
  --chart-card-bg:  #424242;
  --chart-area: #424242;
  --label-text-color: #ffffff;
}

:root .sett{
  --text-color: #212121;
  --text-color-100: #424242;
  --background-color: #e8f3f8;
  --table-bg-color: #ffffff;
  --chart-bg-color: #eeeeee;
  --active-row-bg-color: #eeeeee;
  --active-row-color: #212121;
  --active-cell-bg-color: #0063b6;
  --active-cell-color: #ffffff;
  --cell-border-color: #ffffff;
  --cell-bottom-border-color: #e0e0e0;
  --scroll-bar-bg-color: #eeeeee;
  --scroll-bar-color: #bdbdbd;
  --active-scroll-bar-color: #616161;
  --chart-header-bg-color: #eeeeee;
  --header-active-cell-bg-color: #bdbdbd;
  --header-active-cell-color: #212121;
  --buttons-bg-color: #e1e1e1;
  --buttons-color: #4e4e4e;
  --nav-header-bg-color: #ffffff;
  --nav-header-color: #8dcfee;
  --facet-title-color: #212121;
  --reports-drop-down-bg: #f6f6f6;
  --reports-drop-down-text: #424242;
  --horizontal-facet-button: #0063b6;
  --disco-expansion-panel-body:#bdbdbd;
  --chart-card-bg: #f1f1f1;
  //Variables for charts
  --chart-text-color: #e0e0e0;
  // --single-chart: #00AEEC;
  // --single-chart-border: #bdbdbd;
  --grid-lines: #a0a0a0;
  --inactive-text: #bdbdbd;
  --chart-area: #ffffff;
  --label-text-color: #ffffff;
}
