html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', "Helvetica Neue", sans-serif;
}

.container {
  display: flex;
  padding: 16px;
  border-radius: 8px;
  background: var(--main-color);
  margin: 16px;
}

a {
  color: var(--base-color);
  text-decoration: none;
}

.text-muted {
  opacity: 75%;
  font-size: 12px;
}

.body-text {
  font-size: 12px;
  line-height: 19px;
  font-weight: normal;
}

.content-text {
  font-size: 14px;
  line-height: 21px;
  font-weight: normal;
}

.subheader-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}

.main-header-text {
  font-size: 26px;
  line-height: 40px;
  font-weight: normal;
}

.page-title {
  width: 100%;
  border-radius: 8px;
  background: var(--main-color);
  padding: 20px;
  display: flex;
  align-items: center;

  span {
    color: var(--base-color);
    font-size: 26px;
    padding: 0px 20px;
  }
}

.blue-snackbar {
  background: var(--base-color);
  color: var(--dark-A100);
}

.red-snackbar {
  background: #f44336;
  color: var(--dark-A100);

}




.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge,
.irs-slider {
  background: url(../../assets/images/sprite-skin-flat.png) repeat-x;
}

.irs {
  height: 40px;
}

.irs-with-grid {
  height: 60px;
}

.irs-line {
  height: 12px;
  top: 25px;
}

.irs-line-left {
  height: 12px;
  background-position: 0 -30px;
}

.irs-line-mid {
  height: 12px;
  background-position: 0 0;
}

.irs-line-right {
  height: 12px;
  background-position: 100% -30px;
}

.irs-bar {
  height: 12px;
  top: 25px;
  background-position: 0 -60px;
  background: var(--base-color);

}

.irs-bar-edge {
  top: 25px;
  height: 12px;
  width: 9px;
  background-position: 0 -90px;
}

.irs-shadow {
  height: 3px;
  top: 34px;
  background: #000;
  opacity: 0.25;
}

.lt-ie9 .irs-shadow {
  filter: alpha(opacity=25);
}

.irs-slider {
  width: 16px;
  height: 18px;
  top: 22px;
  background-position: 0 -120px;
}

.irs-slider.state_hover,
.irs-slider:hover {
  background-position: 0 -150px;
}

.irs-min,
.irs-max {
  color: #999;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  top: 0;
  padding: 1px 3px;
  background: #e1e4e9;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.irs-from,
.irs-to,
.irs-single {
  color: #fff;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background: var(--base-color) !important;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.irs-from:after,
.irs-to:after,
.irs-single:after {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: var(--base-color) !important;
}


.irs-grid-pol {
  background: #e1e4e9;
}

.irs-grid-text {
  color: #999;
}

.irs-disabled {}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.8);
}

.panel-class-details-dialog {
  height: 100vh;
  overflow-y: hidden;
  width: 1280px;
}

.panel-class-details-dialog .mat-dialog-container {
  background: var(--dark-300);
  padding: 0px;
  overflow: hidden;
}

.white-class-details-dialog .mat-dialog-container {
  background: var(--dark-50);
  padding: 32px;
  overflow: hidden;
}
.cdk-overlay-backdrop.back-drop-details-dialog{
  background: rgba(0, 0, 0, 0.8);
}

::-webkit-scrollbar {
  background-color: var(--scroll-bar-bg-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
}

.basic-field input {
  border-radius: 4px;
  padding: 8px 8px;
  border: 1px solid #b1b1b1;
  background-color: var(--dark-light);
  color: var(--dark-A100);
  font-size: 13px;
}
.font-weight-100 {
  font-weight: 100;
}
.section-box {
  padding: 14px;
  border: 1px solid var(--dark-300);
  border-radius: 20px;
  margin: 12px 0px;
}

.headder-color {
  color: var(--base-color) !important;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
}

// RR this is not good because it is modifying style in general and it is not limited by a class.

.mat-button-wrapper {
  font-weight: 400;
  display: flex;
  align-items: center;
}

mat-icon {
  cursor: pointer;
}

.mat-radio-button~.mat-radio-button {
  margin-left: 16px;
}

.mat-radio-label-content {
  font-size: 14px;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--base-color);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}

.mat-radio-checked .mat-radio-inner-circle {
  transform: scale(.6) !important;
}

.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
  // background-color: var(--base-color);
}

.mat-calendar-next-button,
.mat-calendar-previous-button {
  color: var(--dark-A100);
}

.full-width-dialog.cdk-overlay-pane {
  max-width: 100vw !important;
}
